import { EditorContent, useEditor } from '@tiptap/react'
import debounce from 'lodash.debounce'
import React, { useEffect, useState } from 'react'
import { SubstituteInterface } from 'shared/components/dashboard-mail-editor/components/substitutes-block'
import { useSubstitutes } from 'shared/components/dashboard-mail-editor/hooks/use-substitutes'
import { convertStyleObjectToCss } from 'shared/utils/style-helpers'
import { twMerge } from 'tailwind-merge'
import Footer from './components/footer'
import ImageBubbleMenu from './components/image-bubble-menu'
import LinkBubbleMenu from './components/link-bubble-menu'
import Toolbar from './components/toolbar'
import { defaultExtensions } from './constans/default-extensions'
import { EDITOR_BORDER, EDITOR_PADDING } from './constans/default-styles'
import { EditorExtensionEnum } from './enums/editor-extensions-enum'
import { useImageDropAndUpload } from './hooks/use-image-drop-and-upload'
import { getExistingExtensions, getExtensions } from './utils/get-extensions'

export interface TipTapEditorProps {
  content: string | undefined
  isPreFetching?: boolean
  isEditable?: boolean
  showFooter?: boolean
  isError?: boolean
  onChange: (data: string) => void
  classNameSkeleton?: string
  additionalSubstitutes?: SubstituteInterface[]
  withoutSubstitutes?: boolean
  extensions?: EditorExtensionEnum[]
  className?: string
}

const TipTapEditor: React.FC<TipTapEditorProps> = ({
  content,
  isPreFetching,
  isEditable = true,
  isError,
  onChange,
  showFooter,
  additionalSubstitutes,
  withoutSubstitutes,
  extensions = defaultExtensions,
  className,
}) => {
  const { onDrop, onPaste } = useImageDropAndUpload()
  const [isMounted, setIsMounted] = useState(false)

  const baseExtensions = [
    EditorExtensionEnum.Document,
    EditorExtensionEnum.Paragraph,
    EditorExtensionEnum.Text,
    EditorExtensionEnum.History,
  ]

  const uniqueExtensions = Array.from(new Set([...baseExtensions, ...extensions]))

  const { hasLink, hasImage } = getExistingExtensions(uniqueExtensions)
  const editor = useEditor({
    content: content,
    immediatelyRender: false,
    onCreate: () => setIsMounted(true),
    extensions: getExtensions(uniqueExtensions),
    onUpdate: ({ editor }) => isMounted && onChange(editor.isEmpty ? '' : editor.getHTML()),
    editorProps: {
      handlePaste: hasImage ? onPaste : undefined,
      handleDrop: hasImage ? onDrop : undefined,
      attributes: {
        class: twMerge(
          `border-[${EDITOR_BORDER}]`,
          'border-gray/30',
          'main-transition-colors',
          'min-h-[500px]',
          'w-full',
          'focus:outline-none',
          'focus:border-blue',
          'font-times',
          'overflow-auto',
          !showFooter && 'rounded-b-lg',
          className,
        ),
        style: convertStyleObjectToCss({
          paddingLeft: EDITOR_PADDING,
          paddingRight: EDITOR_PADDING,
        }),
      },
      editable: () => isEditable || !isPreFetching,
    },
  })
  const { substitutionData } = useSubstitutes(withoutSubstitutes, additionalSubstitutes)

  useEffect(() => {
    if (!editor) return

    const debouncedSetContent = debounce(() => {
      const current = editor.getHTML()
      if (current !== content) {
        editor.commands.setContent(content ?? '', false)
      }
    }, 300)

    debouncedSetContent()

    return () => {
      debouncedSetContent.cancel()
    }
  }, [content, editor])

  return (
    <div
      className={twMerge(
        'relative flex h-full flex-col border rounded-lg border-transparent',
        isError && 'border-danger',
      )}
    >
      {extensions.length !== 0 && (
        <Toolbar editor={editor} substitutions={substitutionData} extensions={uniqueExtensions} />
      )}
      <EditorContent editor={editor} />
      {hasLink && <LinkBubbleMenu editor={editor} />}
      {hasImage && <ImageBubbleMenu editor={editor} />}
      {showFooter && <Footer />}
      {/* this bubble menu works bad because, so decided to disable it */}
      {/* <SelectionBubbleMenu editor={editor} extensions={extensions} /> */}
    </div>
  )
}

export default TipTapEditor
